import {
  ChatBosLogo,
  CliktrackerLogo,
  CopypasteLogo,
  CursorLogo,
  EmojiLogo,
  FBScraperLogo,
  FBHackerLogo,
  FoeOrBroLogo,
  InnerCircleLogo,
  Mysocial360Logo,
  SplashLogo,
  VaWardenLogo,
  CRMLogo,
  BosLogo,
  StorieReelsLogo
} from '../utils/logos';

export const apps = [
  { title: 'ChatBos', logo: ChatBosLogo, id: 'chatbos' },
  { title: 'Social360 CRM', logo: CRMLogo, id: 'crm' },
  { title: 'Inner Circle Deets',logo: InnerCircleLogo, id: 'innercircle_deets', },
  { title: 'Mysocial360', logo: Mysocial360Logo, id: 'mysocial360' },
  { title: 'FB Scraper', logo: FBScraperLogo, id: 'fb_scraper' },
  { title: 'StorieReels', logo: StorieReelsLogo, id: 'storieReels' },
  { title: '360 Emojis', logo: EmojiLogo, id: '360_emojis' },
  { title: 'Copy Paste 360', logo: CopypasteLogo, id: 'copy_paste_360' },
  { title: 'ClikTracker', logo: CliktrackerLogo, id: 'clikTracker' },
  { title: 'Custom Cursors', logo: CursorLogo, id: 'custom_cursors' },
  { title: 'Foe Or Bro', logo: FoeOrBroLogo, id: 'foe_or_bro' },
  //   { title: 'Va Warden', logo: VaWardenLogo, id: 'va_warden' },
  { title: 'FB Group Master', logo: FBHackerLogo, id: 'fb_hacker' },
  { title: 'Splash Mysocial', logo: SplashLogo, id: 'splash' },
];
