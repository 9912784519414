import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DrawflowContext } from '../../context/drawflowContext';
import { PostContext } from '../../context/postContext';
import { DRAWFLOW_ACTIONS } from '../../context/reducers/drawflowReducer';
import { POST_ACTIONS } from '../../context/reducers/postReducer';
import BaseStyleComponent from '../../components/BaseStyleComponent';
import WeekDayComponent from './components/weekDayComponent/WeekDayComponent';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import API from '../../utils/api';
import toast from 'react-hot-toast';

const Scheduler = () => {
  const [state, dispatch] = useContext(DrawflowContext);
  const { token } = useAuthContext();
  const { postState, postDispatch } = useContext(PostContext);
  const [weekDays, setWeekDays] = useState([]);
  const [nav, setNav] = useState(0);
  const [weekPeriod, setWeekPeriod] = useState();
  const [headerColor, setHeaderColor] = useState();
  const [todayColor, setTodayColor] = useState();
  const [buttonColor, setButtonColor] = useState();
  const [buttonFontColor, setButtonFontColor] = useState();
  const [fontColor, setFontColor] = useState();
  const {
    state: { startStr, type },
  } = useLocation();

  const handleNextWeek = () => setNav((prev) => prev + 7);
  const handleLastWeek = () => setNav((prev) => prev - 7);
  const handleToday = () => setNav(0);

  const getCalendarColor = async () => {
    try {
      const res = await API.Get('calendar/', token);
      if (res.data.result) {
        const calendarSettings = res.data.calendarColorSettings;

        setHeaderColor(calendarSettings.dayTopColor);
        setTodayColor(calendarSettings.todayColor);
        setButtonColor(calendarSettings.buttonsColor);
        setButtonFontColor(calendarSettings.buttonsFontColor);
        setFontColor(calendarSettings.dateFontColor);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCalendarColor();
  }, []);

  const handleCreatePost = (body) => {
    const response = API.Post('/events', body, token);

    toast.promise(response, {
      loading: 'Adding Post',
      success: ({ data }) => {
        if (type === 'event') {
          getEvents();
        }
        return 'Post added successfully';
      },
      error: ({ response }) => {
        console.log(response);
      },
    });
  };

  const handleEditPost = (id, body) => {
    const response = API.Patch(`/events/${id}`, body, token);

    toast.promise(response, {
      loading: 'Editing Post...',
      success: ({ data }) => {
        if (type === 'event') {
          getEvents();
        }
        return 'Edit successfully';
      },
      error: ({ response }) => {
        console.log(response);
      },
    });
  };

  const handleDeletePost = (id) => {
    const response = API.Delete(`/events/${id}`, token);

    toast.promise(response, {
      loading: 'Deleting Post',
      success: ({ data }) => {
        if (type === 'event') {
          getEvents();
        }
        return 'Delete Successful';
      },
      error: ({ response }) => {
        return 'Delete error';
      },
    });
  };

  const getEvents = useCallback(async () => {
    if (token) {
      try {
        postDispatch({
          type: POST_ACTIONS.LOADING,
          payload: true,
        });
        const response = await API.Get(`/events`, token);

        if (response.data) {
          const events = response.data.map((event) => ({
            id: event._id,
            title: event.name,
            date: event.startDate,
            startTime: event.startTime,
          }));

          postDispatch({
            type: POST_ACTIONS.ADD_POSTS,
            field: 'events',
            payload: events,
          });
          postDispatch({
            type: POST_ACTIONS.LOADING,
            payload: false,
          });
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (type === 'event') {
      getEvents();
    }
  }, [type, getEvents]);

  useEffect(() => {
    const eventsForDate = (date) => {
      const eventsFRDate = [];
      postState.events.forEach((e) => {
        if (e.date === date) eventsFRDate.push(e);
      });
      return eventsFRDate;
    };

    const dt = new Date(startStr);

    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const day = dt.getDate();
    const firstDayOfWeek = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate() - dt.getDay()
    );
    let d;

    if (nav !== 0) {
      d = new Date(dt.setDate(firstDayOfWeek.getDate() + nav));
    } else {
      d = new Date(firstDayOfWeek);
    }
    const week = [new Date(d)];
    let i = 6;
    while (i--) {
      week.push(new Date(d.setDate(d.getDate() + 1)));
    }

    setWeekPeriod(
      `${week[0].toLocaleDateString('en-us', {
        month: 'long',
        day: 'numeric',
        year: '2-digit',
      })} - 
      ${week[week.length - 1].toLocaleDateString('en-us', {
        month: 'long',
        day: 'numeric',
        year: '2-digit',
      })}`
    );

    const daysArr = [];

    week.forEach((days) => {
      const realDate = `${
        days.getMonth() + 1
      }/${days.getDate()}/${days.getFullYear()}`;
      const dayObj = {
        isCurrentDay: days.getDate() === day && nav === 0,
        day: `${weekdays[days.getDay()]}`,
        date: ` ${days.toLocaleDateString('en-us', {
          day: '2-digit',
        })} `,
        realDate,
        events: eventsForDate(realDate),
      };

      daysArr.push(dayObj);
    });
    setWeekDays(daysArr);
  }, [nav, postState.events]);

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, []);
  return (
    <>
      <BaseStyleComponent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={6}
          mb={2}
        >
          <Stack direction="row" spacing={2}>
            <ButtonGroup
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: '30px',
              }}
            >
              <Button
                sx={{
                  borderRadius: '30px',
                  color: buttonFontColor,
                  background: buttonColor,
                  '&:hover': { background: buttonColor, opacity: 0.9 },
                }}
                onClick={handleLastWeek}
              >
                {<ChevronLeftIcon />}
              </Button>
              <Button
                sx={{
                  borderRadius: '30px',
                  color: buttonFontColor,
                  background: buttonColor,
                  '&:hover': { background: buttonColor, opacity: 0.9 },
                }}
                onClick={handleNextWeek}
              >
                {<ChevronRightIcon />}
              </Button>
            </ButtonGroup>
            <Button
              variant="contained"
              color="inherit"
              sx={{
                borderRadius: '30px',
                color: buttonFontColor,
                background: buttonColor,
                '&:hover': { background: buttonColor, opacity: 0.9 },
              }}
              onClick={handleToday}
            >
              Selected Day
            </Button>
          </Stack>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '20px', sm: '26px' },
              fontWeight: { xs: '600' },
            }}
          >
            {weekPeriod ?? ''}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            width: '100%',
            height: '100%',
            marginBottom: '250px',
          }}
        >
          {weekDays.map((day, index) => (
            <WeekDayComponent
              key={index}
              weekDay={day}
              headerColor={headerColor}
              todayColor={todayColor}
              fontColor={fontColor}
              handleCreatePost={handleCreatePost}
              handleDeletePost={handleDeletePost}
              handleEditPost={handleEditPost}
            />
          ))}
        </Stack>
      </BaseStyleComponent>
    </>
  );
};

export default Scheduler;
