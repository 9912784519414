import React, { useEffect, useContext, useState } from 'react';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import BaseStyleComponent from '../components/BaseStyleComponent';
import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useAuthContext } from '../hooks/useAuthContext';
import { validateInput } from '../utils/form_validator';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import toast from 'react-hot-toast';
import API from '../utils/api';

const SettingsPage = ({ setIsFlow }) => {
  const [state, dispatch] = useContext(DrawflowContext);
  const { user, token } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    oldPassword: { value: '', touched: false, hasError: true, error: '' },
    newPassword: { value: '', touched: false, hasError: true, error: '' },
    confirmPassword: { value: '', touched: false, hasError: true, error: '' },
    isFormValid: false,
  });

  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, [dispatch, state.isFlow]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { hasError, error } = validateInput(name, value);
    let isFormValid = true;

    for (const key in userCredentials) {
      const item = userCredentials[key];
      if (key === name && hasError) {
        isFormValid = false;
        break;
      } else if (key !== name && item && item.hasError) {
        isFormValid = false;
        break;
      }
    }

    setUserCredentials({
      ...userCredentials,
      [name]: {
        ...userCredentials[name],
        value: value,
        hasError,
        error,
        touched: false,
      },
      isFormValid,
    });
  };

  const onFocusOut = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { hasError, error } = validateInput(
      name === 'newPassword' ? 'password' : name,
      value,
      userCredentials.newPassword.value
    );
    let isFormValid = true;

    for (const key in userCredentials) {
      const item = userCredentials[key];
      if (key === name && hasError) {
        isFormValid = false;
        break;
      } else if (key !== name && item && item.hasError) {
        isFormValid = false;
        break;
      }
    }

    setUserCredentials({
      ...userCredentials,
      [name]: {
        ...userCredentials[name],
        value: value,
        hasError,
        error,
        touched: true,
      },
      isFormValid,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userCredentials.isFormValid)
      return toast.error('Please Fill all fields!');
    const body = {
      oldPassword: userCredentials.oldPassword.value,
      newPassword: userCredentials.newPassword.value,
    };

    const res = API.Patch('/auth/changePassword', body, token);

    toast.promise(res, {
      loading: 'Changing Password...',
      success: ({ data }) => {
        resetFields();
        return 'Password Changed Successfully';
      },
      error: ({ response }) => {
        switch (response.status) {
          case 400:
            return response.data.message;

          default:
            return 'Error Changing Password';
        }
      },
    });
  };

  const resetFields = () => {
    setShowPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setUserCredentials({
      oldPassword: { value: '', touched: false, hasError: true, error: '' },
      newPassword: { value: '', touched: false, hasError: true, error: '' },
      confirmPassword: { value: '', touched: false, hasError: true, error: '' },
      isFormValid: false,
    });
  };

  return (
    <>
      <BaseStyleComponent>
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Avatar
            src={user.image}
            alt={user.name}
            sx={{ width: 64, height: 64 }}
          />
          <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="h5">{user.name}</Typography>
            <Typography variant="h6">{user.email}</Typography>
          </Stack>
          <Typography variant="h6">Change Password</Typography>
          <form onSubmit={handleSubmit}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ width: { xs: '320px', sm: '500px' } }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  userCredentials.oldPassword.touched &&
                  userCredentials.oldPassword.hasError
                    ? true
                    : false
                }
              >
                <InputLabel htmlFor="oldPassword">Old Password</InputLabel>
                <OutlinedInput
                  id="oldPassword"
                  label="Old Password"
                  type={showPassword ? 'text' : 'password'}
                  name="oldPassword"
                  value={userCredentials.oldPassword.value}
                  onChange={handleChange}
                  onBlur={onFocusOut}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {userCredentials.oldPassword.touched &&
                  userCredentials.oldPassword.hasError
                    ? userCredentials.oldPassword.error
                    : ''}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  userCredentials.newPassword.touched &&
                  userCredentials.newPassword.hasError
                    ? true
                    : false
                }
              >
                <InputLabel htmlFor="newPassword">New Password</InputLabel>
                <OutlinedInput
                  id="newPassword"
                  label="New Password"
                  type={showNewPassword ? 'text' : 'password'}
                  name="newPassword"
                  value={userCredentials.newPassword.value}
                  onChange={handleChange}
                  onBlur={onFocusOut}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {userCredentials.newPassword.touched &&
                  userCredentials.newPassword.hasError
                    ? userCredentials.newPassword.error
                    : ''}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={
                  userCredentials.confirmPassword.touched &&
                  userCredentials.confirmPassword.hasError
                    ? true
                    : false
                }
              >
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={userCredentials.confirmPassword.value}
                  onChange={handleChange}
                  onBlur={onFocusOut}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {userCredentials.confirmPassword.touched &&
                  userCredentials.confirmPassword.hasError
                    ? userCredentials.confirmPassword.error
                    : ''}
                </FormHelperText>
              </FormControl>
              <Button type="submit" variant="contained">
                Save Changes
              </Button>
            </Stack>
          </form>
          <Button variant="outlined" color="error">
            Delete Account
          </Button>
        </Stack>
      </BaseStyleComponent>
    </>
  );
};

export default SettingsPage;
