import React, { useCallback, useEffect, useState, useContext } from 'react';
import '../../drawFlowPage/css/drawflow.css';
import '../../drawFlowPage/css/beautiful.css';
import '../../drawFlowPage/css/challengesCss.css';
import debounce from 'lodash.debounce';
import Drawflow from '../../../utils/drawflowJs';
import Selectables from '../../../utils/selectables';
// import Drawflow from 'drawflow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SearchIcon from '@mui/icons-material/Search';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import NotesIcon from '@mui/icons-material/Notes';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/Mail';
import ImageIcon from '@mui/icons-material/Image';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';

import BaseStyleComponent from '../../../components/BaseStyleComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { FacebookLogo, MessageLogo } from '../../../utils/logos';
import { apps } from '../../../constants/apps_data';
import swal from 'sweetalert';
import { DrawflowContext } from '../../../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../../../context/reducers/drawflowReducer';
import API from '../../../utils/api';
import { useAuthContext } from '../../../hooks/useAuthContext';
import toast from 'react-hot-toast';
import * as Blocks from '../../../constants/blocks';
import { zoom_check } from '../../../constants/zoom_check';
import CloseIcon from '@mui/icons-material/Close';
import { chatbosData } from '../../../constants/drawflow_default_data';

const style = {
  position: 'absolute',
  width: '100% !important',
  height: '100vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  border: 'none',
};

const DrawflowModal = ({ openDrawflowModal, handleClose }) => {
  const [state, dispatch] = useContext(DrawflowContext);
  const { token } = useAuthContext();
  const [editorState, setEditorState] = useState(new Drawflow());
  const [mobileItemSelected, setMobileItemSelected] = useState('');
  const [mobileLastMove, setMobileLastMove] = useState(null);
  const [transform, setTransform] = useState('');
  const [zoomLevel, setZoomLevel] = useState();
  const [nodesSelected, setNodesSelected] = useState([]);
  const [lastCords, setLastCords] = useState({ last_x: 0, last_y: 0 });
  var [isMultiSelect, setIsMultiSelect] = useState(false);
  var [multArr, setMultArr] = useState([]);
  var [multiselectDict, setMultiselectDict] = useState({});
  var [dragStart, setDragStart] = useState(false);
  var [activeNodeId, setActiveNodeId] = useState(null);
  const [canvasPosition, setCanvasPosition] = useState({ x: null, y: null });
  const [dataToImport, setDataToImport] = useState({});
  const [imageToBeDeleted, setImageToBeDeleted] = useState({
    id: '',
    image: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [lockFlow, setLockFlow] = useState(true);
  const navigate = useNavigate();
  let is_multiselect = false;
  var mult_arr = [];

  let multiselect_dict = {};
  let drag_start = false;
  let active_node_id = null;

  let dr;

  //   const {
  //     state: { platform, id },
  //   } = useLocation();

  //   const [DrawflowModal, setOpen] = useState(false);

  //   const handleDrawer = () => {
  //     setOpen((prev) => !prev);
  //   };

  //   const challengeCssChange = () => {
  //     document.documentElement.style.setProperty('--top-value', '100% - 10px');
  //     document.documentElement.style.setProperty('--left-value', '50%');
  //     document.documentElement.style.setProperty(
  //       '--elem-inputs-left-value',
  //       '50%'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-outputs-left-value',
  //       '50%'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-inputs-top-value',
  //       ' 0% - 10px'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-outputs-top-value',
  //       '100% - 10px'
  //     );
  //   };

  //   const flowCssChange = () => {
  //     document.documentElement.style.setProperty('--top-value', '45%');
  //     document.documentElement.style.setProperty('--left-value', '95%');
  //     document.documentElement.style.setProperty(
  //       '--elem-inputs-left-value',
  //       '0%'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-outputs-left-value',
  //       '100%'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-inputs-top-value',
  //       '30%'
  //     );
  //     document.documentElement.style.setProperty(
  //       '--elem-outputs-top-value',
  //       '30%'
  //     );
  //   };

  //   useEffect(() => {
  //     dispatch({
  //       type: DRAWFLOW_ACTIONS.IS_FLOW,
  //       field: 'isFlow',
  //       payload: true,
  //     });
  //     (async () => {
  //       if (platform === 'fb_hacker_challenge') {
  //         challengeCssChange();
  //         //Get Challenge
  //         const challenge = await fetchChallenge(id);
  //         setDataToImport(challenge);
  //         setTimeout(async () => {
  //           setIsLoading(false);
  //         }, 1000);
  //       } else {
  //         flowCssChange();
  //         const flow = await fetchFlow(id);
  //         setDataToImport(flow);
  //         setTimeout(async () => {
  //           setIsLoading(false);
  //         }, 1000);
  //       }
  //     })();
  //   }, []);

  //   const fetchChallenge = async (flowID) => {
  //     try {
  //       const res = await API.Get(`/challenge/getChallenge/${flowID}`, token);

  //       setZoomLevel(res.data.zoom['$numberDecimal'].toLocaleString());
  //       setCanvasPosition({
  //         ...canvasPosition,
  //         x: res.data.canvas.x,
  //         y: res.data.canvas.y,
  //       });
  //       return res.data.data;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   const fetchFlow = async (flowID) => {
  //     try {
  //       const res = await API.Get(`/flow/${flowID}`, token);
  //       setZoomLevel(res.data.zoom['$numberDecimal'].toLocaleString());
  //       setCanvasPosition({
  //         ...canvasPosition,
  //         x: res.data.canvas.x,
  //         y: res.data.canvas.y,
  //       });
  //       return res.data.flow;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const editorRef = useCallback(
    async (node) => {
      if (node) {
        const editor = new Drawflow(node);
        editor.reroute = true;
        editor.zoom_min = 0.005;

        // editor.createCurvature = function (
        //   start_x,
        //   start_y,
        //   end_x,
        //   end_y,
        //   curvature_value,
        //   type
        // ) {
        //   var center_y = (end_y - start_y) / 2 + start_y;
        //   var default_round_radius = 10;
        //   var round_radius = Math.min(
        //     default_round_radius,
        //     Math.abs(start_x - end_x),
        //     Math.abs(start_y - end_y)
        //   );

        //   var isRight = end_x > start_x;
        //   var isDown = end_y > start_y;
        //   return `M ${start_x} ${start_y}
        //     L ${start_x} ${
        //     isDown ? center_y - round_radius : center_y + round_radius
        //   }
        //     A ${round_radius} ${round_radius} 0 0 ${
        //     isRight ^ !isDown ? 0 : 1
        //   } ${
        //     isRight ? start_x + round_radius : start_x - round_radius
        //   } ${center_y}
        //     L ${
        //       isRight ? end_x - round_radius : end_x + round_radius
        //     } ${center_y}
        //     A ${round_radius} ${round_radius} 0 0 ${
        //     isRight ^ !isDown ? 1 : 0
        //   } ${end_x} ${
        //     isDown ? center_y + round_radius : center_y - round_radius
        //   }
        //     L ${end_x} ${end_y}`;
        // };

        editor.start();
        editor.import(chatbosData);

        // if (zoomLevel) {
        //   const zoom_level = await zoom_check(zoomLevel);
        //   for (let i = 0; i < zoom_level; i++) {
        //     if (parseInt(zoomLevel) > 0) {
        //       editor.zoom_in();
        //     } else {
        //       editor.zoom_out();
        //     }
        //   }
        // }

        // const default_x = platform === 'fb_hacker_challenge' ? -204 : -13;
        // const default_y = platform === 'fb_hacker_challenge' ? -7 : -21;
        // const x =
        //   typeof canvasPosition.x !== 'undefined' && canvasPosition.x !== null
        //     ? canvasPosition.x
        //     : default_x;
        // const y =
        //   typeof canvasPosition.y !== 'undefined' && canvasPosition.y !== null
        //     ? canvasPosition.y
        //     : default_y;
        // const scale = zoomLevel ? zoomLevel : 0.9;

        // editor.precanvas.style.transform = `translate(${x}px,  ${y}px) scale(${scale})`;

        setEditorState(editor);
      }
    },
    [
      // canvasPosition.x,
      // canvasPosition.y,
      // dataToImport,
      // //   platform,
      // zoomLevel,
    ]
  );

  //   const deleteImage = () => {
  //     const res = API.Delete(`/images/${imageToBeDeleted.image}`, token);
  //     toast.promise(res, {
  //       loading: 'Deleting Image',
  //       success: 'Image deleted',
  //       error: (error) => {
  //         return 'Error Deleting';
  //       },
  //     });
  //   };

  //   editorState.on('nodeCreated', async (id) => {
  //     editorImageUploadListenerDebounceFn();
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //     if (dr) {
  //       dr.disable();
  //       dr.enable();
  //     }
  //   });

  //   editorState.on('nodeRemoved', async (id) => {
  //     if (imageToBeDeleted.id === id) {
  //       deleteImage();
  //     }
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //     if (dr) {
  //       dr.disable();
  //       dr.enable();
  //     }
  //   });

  //   editorState.on('connectionStart', async (id) => {
  //     //toast(`connection started`);
  //   });
  //   editorState.on('connectionCancel', async (id) => {
  //     //toast(`connection cancelled:${id} `);
  //   });

  //   editorState.on('connectionCreated', async (connection) => {
  //     editorImageUploadListenerDebounceFn();
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //     if (dr) {
  //       dr.disable();
  //       dr.enable();
  //     }
  //   });

  //   editorState.on('connectionRemoved', async (connection) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //     if (dr) {
  //       dr.disable();
  //       dr.enable();
  //     }
  //   });

  //   editorState.on('nodeMoved', async (id) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('translate', async (position) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('nodeSelected', async (id) => {});

  //   editorState.on('contextmenu', async (e) => {
  //     const id = e.target.closest('.drawflow-node').id.slice(5);
  //     if (id) {
  //       const node = editorState.getNodeFromId(id);
  //       const nodeData = node.data;

  //       if (nodeData['df-image']) {
  //         imageTobeDeletedDebounceFn(id, nodeData);
  //       }
  //     }

  //     const nodeContainer = document.querySelector(`#node-1`);
  //     let elementFound = false;

  //     const observer = new MutationObserver((mutations) => {
  //       mutations.forEach((mutation) => {
  //         if (mutation.addedNodes.length) {
  //           for (let i = 0; i < mutation.addedNodes.length; i++) {
  //             const deleteBtn = document.querySelectorAll('.drawflow-delete');

  //             if (deleteBtn.length > 0) {
  //               //stop loop
  //               i = mutation.addedNodes.length;
  //               elementFound = true;
  //               observer.disconnect();
  //             }
  //           }
  //         }
  //       });
  //       if (elementFound) {
  //         run();
  //       }
  //     });

  //     observer.observe(nodeContainer, { childList: true, subtree: true });
  //     const run = () => {
  //       const deleteBtn = document.querySelector('.drawflow-delete');
  //       deleteBtn.remove();
  //     };
  //   });

  //   editorState.on('addReroute', async (id) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('removeReroute', async (id) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('rerouteMoved', async (id) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('nodeDataChanged', async (id) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('zoom', async (zoom_level) => {
  //     await addFlow(editorState);
  //     editorStateDebounceFn(editorState);
  //   });

  //   editorState.on('mouseMove', (position) => {
  //     //console.log('Position mouse x:' + position.x + ' y:'+ position.y);
  //     if (drag_start === true) {
  //       for (const i of mult_arr) {
  //         if (i !== active_node_id) {
  //           if (
  //             typeof editorState.drawflow.drawflow.Home.data[i] !== 'undefined'
  //           ) {
  //             let node = editorState.getNodeFromId(active_node_id);
  //             let elem = document.getElementById('node-' + i).children[1]
  //               .children[0];
  //             let pos_x = multiselect_dict[i]['pos_x'];
  //             let pos_y = multiselect_dict[i]['pos_y'];
  //             editorState.drawflow.drawflow.Home.data[i].pos_x =
  //               node.pos_x + pos_x;
  //             editorState.drawflow.drawflow.Home.data[i].pos_y =
  //               node.pos_y + pos_y;
  //             document.getElementById(`node-${i}`).style.left =
  //               node.pos_x + pos_x + 'px';
  //             document.getElementById(`node-${i}`).style.top =
  //               node.pos_y + pos_y + 'px';
  //             editorState.updateConnectionNodes(`node-${i}`);
  //           }
  //         }
  //       }
  //     }
  //   });

  //   editorState.on('doubleClick', (e) => {
  //     showpopup(e);
  //   });

  //   const positionMobile = (ev) => {
  //     setMobileLastMove(ev);
  //   };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  //   const drag = (ev) => {
  //     if (ev.type === 'touchstart') {
  //       setMobileItemSelected(
  //         ev.target.closest('.drag-item').getAttribute('data-node')
  //       );
  //     } else {
  //       ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
  //     }
  //   };

  //   const imageTobeDeletedDebounceFn = useCallback(
  //     debounce((id, nodeData) => {
  //       setImageToBeDeleted({
  //         id,
  //         image: nodeData['df-image'].replace(
  //           'http://localhost:5000/crm/api/images/',
  //           ''
  //         ),
  //       });
  //     }, 500),
  //     []
  //   );

  //   const editorStateDebounceFn = useCallback(
  //     debounce(async (editor) => {
  //       dispatch({
  //         type: DRAWFLOW_ACTIONS.SAVING,
  //         payload: true,
  //       });
  //       //   await saveFlow(editor);
  //       setTimeout(() => {
  //         dispatch({
  //           type: DRAWFLOW_ACTIONS.SAVING,
  //           payload: false,
  //         });
  //       }, 1500);
  //     }, 500),
  //     []
  //   );

  //   const editorImageUploadListenerDebounceFn = useCallback(
  //     debounce(() => {
  //       imageUploadListener(editorState);
  //     }, 500),
  //     [editorState]
  //   );

  //   const saveFlow = async (editor) => {
  //     try {
  //       const body = {
  //         id: id,
  //         flow: editor.drawflow,
  //         data: editor.drawflow,
  //         days: editor.drawflow.drawflow.Home.data.length - 1,
  //         zoom_level: editor.zoom,
  //         canvas: { x: editor.canvas_x, y: editor.canvas_y },
  //       };

  //       const response = await API.Patch(
  //         platform === 'fb_hacker_challenge' ? `/challenge/${id}` : '/saveFlow',
  //         body,
  //         token
  //       );
  //     } catch (error) {
  //       if (error.response.status === 500)
  //         return swal({ title: 'Something went wrong', icon: 'error' });
  //     }
  //   };

  //   const addFlow = () => {
  //     dispatch({
  //       type: DRAWFLOW_ACTIONS.HANDLE_FLOW,
  //       field: 'flow',
  //       payload: { name: 'drawflow', value: editorState.drawflow.drawflow },
  //     });
  //   };

  const moveDebounceFn = useCallback(
    debounce((editor, item, move) => {
      if (move)
        return addNodeToDrawFlow(
          editor,
          item,
          move.touches[0].clientX,
          move.touches[0].clientY
        );
    }, 100),
    []
  );

  const drop = async (ev) => {
    if (ev.type === 'touchend' && mobileLastMove != null) {
      const parentdrawflow = document
        .elementFromPoint(
          mobileLastMove.touches[0].clientX,
          mobileLastMove.touches[0].clientY
        )
        .closest('#drawflow');

      if (parentdrawflow != null && mobileLastMove != null) {
        await moveDebounceFn(editorState, mobileItemSelected, mobileLastMove);
      }
      setMobileItemSelected('');
    } else {
      ev.preventDefault();
      let data;
      if (ev.dataTransfer) {
        data = ev.dataTransfer.getData('node');
      }
      addNodeToDrawFlow(editorState, data, ev.clientX, ev.clientY);
    }
  };

  const addNodeToDrawFlow = (editor, name, pos_x, pos_y) => {
    if (editor.editor_mode === 'fixed') {
      return false;
    }

    pos_x =
      pos_x *
        (editor.precanvas.clientWidth /
          (editor.precanvas.clientWidth * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().x *
        (editor.precanvas.clientWidth /
          (editor.precanvas.clientWidth * editor.zoom));
    pos_y =
      pos_y *
        (editor.precanvas.clientHeight /
          (editor.precanvas.clientHeight * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().y *
        (editor.precanvas.clientHeight /
          (editor.precanvas.clientHeight * editor.zoom));

    switch (name) {
      case 'text':
        const text = Blocks.getText();
        editor.addNode(
          text.id,
          1,
          1,
          pos_x,
          pos_y,
          text.class,
          text.data,
          text.html
        );
        break;
      case 'image':
        const image = Blocks.getImage();
        editor.addNode(
          image.id,
          1,
          1,
          pos_x,
          pos_y,
          image.class,
          image.data,
          image.html
        );
        break;
      case 'chatbos':
        const chatbos = Blocks.getChatbos();
        editor.addNode(
          chatbos.id,
          1,
          1,
          pos_x,
          pos_y,
          chatbos.class,
          chatbos.data,
          chatbos.html
        );
        break;
      case 'crm':
        const crm = Blocks.getSocial360CRM();
        editor.addNode(
          crm.id,
          1,
          1,
          pos_x,
          pos_y,
          crm.class,
          crm.data,
          crm.html
        );
        break;
      case 'mysocial360':
        const mysocial360 = Blocks.getMysocial360();
        editor.addNode(
          mysocial360.id,
          1,
          1,
          pos_x,
          pos_y,
          mysocial360.class,
          mysocial360.data,
          mysocial360.html
        );
        break;
      case 'fb_scraper':
        const fbScraper = Blocks.getFbscraper();
        editor.addNode(
          fbScraper.id,
          1,
          1,
          pos_x,
          pos_y,
          fbScraper.class,
          fbScraper.data,
          fbScraper.html
        );
        break;
      case 'storieReels':
        const storiereels = Blocks.getStorieReels();
        editor.addNode(
          storiereels.id,
          1,
          1,
          pos_x,
          pos_y,
          storiereels.class,
          storiereels.data,
          storiereels.html
        );
        break;
      case '360_emojis':
        const emoji360 = Blocks.get360Emojis();
        editor.addNode(
          emoji360.id,
          1,
          1,
          pos_x,
          pos_y,
          emoji360.class,
          emoji360.data,
          emoji360.html
        );
        break;
      case 'copy_paste_360':
        const copy_paste_360 = Blocks.getCopyPaste360();
        editor.addNode(
          copy_paste_360.id,
          1,
          1,
          pos_x,
          pos_y,
          copy_paste_360.class,
          copy_paste_360.data,
          copy_paste_360.html
        );
        break;
      case 'clikTracker':
        const clikTracker = Blocks.getCliktracker();
        editor.addNode(
          clikTracker.id,
          1,
          1,
          pos_x,
          pos_y,
          clikTracker.class,
          clikTracker.data,
          clikTracker.html
        );
        break;
      case 'foe_or_bro':
        const foeOrBro = Blocks.getFoeOrBro();
        editor.addNode(
          foeOrBro.id,
          1,
          1,
          pos_x,
          pos_y,
          foeOrBro.class,
          foeOrBro.data,
          foeOrBro.html
        );
        break;
      case 'innercircle_deets':
        const innerCircle = Blocks.getInnerCircleDeets();
        editor.addNode(
          innerCircle.id,
          1,
          1,
          pos_x,
          pos_y,
          innerCircle.class,
          innerCircle.data,
          innerCircle.html
        );
        break;
      case 'fb_hacker':
        const fbHacker = Blocks.getFBGroupHacker();
        editor.addNode(
          fbHacker.id,
          1,
          1,
          pos_x,
          pos_y,
          fbHacker.class,
          fbHacker.data,
          fbHacker.html
        );
        break;
      case 'delay':
        const delay = Blocks.getDelay();
        editor.addNode(
          delay.id,
          1,
          1,
          pos_x,
          pos_y,
          delay.class,
          delay.data,
          delay.html
        );
        break;
      case 'email':
        const email = Blocks.getEmail();
        editor.addNode(
          email.id,
          1,
          0,
          pos_x,
          pos_y,
          email.class,
          email.data,
          email.html
        );
        break;
      case 'notification':
        const notification = Blocks.getNotification();
        editor.addNode(
          notification.id,
          1,
          0,
          pos_x,
          pos_y,
          notification.class,
          notification.data,
          notification.html
        );
        break;
      case 'fbpost':
        const fbpost = Blocks.getFBPost();
        editor.addNode(
          fbpost.id,
          1,
          1,
          pos_x,
          pos_y,
          fbpost.class,
          fbpost.data,
          fbpost.html
        );
        break;
      case 'messenger':
        const messenger = Blocks.getFBMessenger();
        editor.addNode(
          messenger.id,
          1,
          1,
          pos_x,
          pos_y,
          messenger.class,
          messenger.data,
          messenger.html
        );
        break;
      case 'telegram':
        var telegrambot = `
            <div>
              <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>
              <div class="box">
                <p>Send to telegram</p>
                <p>select channel</p>
                <select df-channel>
                  <option value="channel_1">Channel 1</option>
                  <option value="channel_2">Channel 2</option>
                  <option value="channel_3">Channel 3</option>
                  <option value="channel_4">Channel 4</option>
                </select>
              </div>
            </div>
            `;
        editor.addNode(
          'telegram',
          1,
          0,
          pos_x,
          pos_y,
          'telegram',
          { channel: 'channel_3' },
          telegrambot
        );
        break;
      case 'aws':
        var aws = `
            <div>
              <div class="title-box"><i class="fab fa-aws"></i> Aws Save </div>
              <div class="box">
                <p>Save in aws</p>
                <input type="text" df-db-dbname placeholder="DB name"><br><br>
                <input type="text" df-db-key placeholder="DB key">
                <p>Output Log</p>
              </div>
            </div>
            `;
        editor.addNode(
          'aws',
          1,
          1,
          pos_x,
          pos_y,
          'aws',
          { db: { dbname: '', key: '' } },
          aws
        );
        break;
      case 'log':
        var log = `
              <div>
                <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>
              </div>
              `;
        editor.addNode('log', 1, 0, pos_x, pos_y, 'log', {}, log);
        break;
      case 'google':
        var google = `
              <div>
                <div class="title-box"><i class="fab fa-google-drive"></i> Google Drive save </div>
              </div>
              `;
        editor.addNode('google', 1, 0, pos_x, pos_y, 'google', {}, google);
        break;
      case 'template':
        var template = `
              <div>
                <div class="title-box"><i class="fas fa-code"></i> Template</div>
                <div class="box">
                  Ger Vars
                  <textarea df-template></textarea>
                  Output template with vars
                </div>
              </div>
              `;
        editor.addNode(
          'template',
          1,
          1,
          pos_x,
          pos_y,
          'template',
          { template: 'Write your template' },
          template
        );
        break;
      case 'multiple':
        var multiple = `
              <div>
                <div class="box">
                  Multiple!
                </div>
              </div>
              `;
        editor.addNode(
          'multiple',
          3,
          4,
          pos_x,
          pos_y,
          'multiple',
          {},
          multiple
        );
        break;
      case 'personalized':
        var personalized = `
              <div>
                Personalized
              </div>
              `;
        editor.addNode(
          'personalized',
          1,
          1,
          pos_x,
          pos_y,
          'personalized',
          {},
          personalized
        );
        break;
      case 'dbclick':
        var dbclick = `
              <div>
              <div class="title-box"><i class="fas fa-mouse"></i> Db Click</div>
                <div class="box dbclickbox" ondblclick="showpopup(event)">
                  Db Click here
                  <div class="modal" style="display:none">
                    <div class="modal-content">
                      <span class="close" onclick="closemodal(event)">&times;</span>
                      Change your variable {name} !
                      <input type="text" df-name>
                    </div>

                  </div>
                </div>
              </div>
              `;
        editor.addNode(
          'dbclick',
          1,
          1,
          pos_x,
          pos_y,
          'dbclick',
          { name: '' },
          dbclick
        );
        break;

      default:
    }
  };

  const showpopup = (e) => {
    // e.target.closest('.drawflow-node').style.zIndex = '9999';
    // e.target.children[0].style.display = 'block';
    // //document.getElementByclass("modalfix").style.display = "block";

    // //e.target.children[0].style.transform = 'translate('+translate.x+'px, '+translate.y+'px)';
    // setTransform(editorState.precanvas.style.transform);
    // editorState.precanvas.style.transform = '';
    // editorState.precanvas.style.left = editorState.canvas_x + 'px';
    // editorState.precanvas.style.top = editorState.canvas_y + 'px';
    console.log(e.target.children);

    //e.target.children[0].style.top  =  -editor.canvas_y - editor.container.offsetTop +'px';
    //e.target.children[0].style.left  =  -editor.canvas_x  - editor.container.offsetLeft +'px';
    // editorState.editor_mode = 'fixed';
  };

  const closemodal = (e) => {
    e.target.closest('.drawflow-node').style.zIndex = '2';
    e.target.parentElement.parentElement.style.display = 'none';
    //document.getElementByclass("modalfix").style.display = "none";
    editorState.precanvas.style.transform = transform;
    editorState.precanvas.style.left = '0px';
    editorState.precanvas.style.top = '0px';
    editorState.editor_mode = 'edit';
  };

  const changeModule = (event) => {
    const all = document.querySelectorAll('.menu ul li');
    for (let i = 0; i < all.length; i++) {
      all[i].classList.remove('selected');
    }
    event.target.classList.add('selected');
  };

  const changeMode = (option) => {
    if (lockFlow) {
      editorState.editor_mode = 'fixed';
    } else {
      editorState.editor_mode = 'edit';
    }

    return setLockFlow((prev) => !prev);
  };

  //   const runSelectable = () => {
  //     const timer = setInterval(() => {
  //       if (
  //         JSON.stringify(editorState.drawflow.drawflow.Home.data) !==
  //         JSON.stringify({})
  //       ) {
  //         const drawflow = document.querySelectorAll('#drawflow');
  //         if (drawflow) {
  //           dr = new Selectables({
  //             zone: '#drawflow',
  //             elements: ['.box', '.title-box'],

  //             selectedClass: 'active',

  //             key: 'ctrlKey',
  //             moreUsing: 'ctrlKey',

  //             start: function (e) {
  //               if (e.ctrlKey) {
  //                 is_multiselect = true;
  //                 editorState.editor_selected = false;
  //                 editorState.editor_mode = 'fixed';
  //               }
  //             },

  //             stop: function (e) {
  //               editorState.editor_mode = 'edit';
  //               is_multiselect = false;
  //             },

  //             onSelect: function (el) {
  //               if (el.closest('.drawflow-node').id.includes('node-') === true) {
  //                 let id = parseInt(el.closest('.drawflow-node').id.slice(5));
  //                 document
  //                   .getElementById('node-' + id)
  //                   .addEventListener('mousedown', node_mousedown, false);
  //                 document
  //                   .getElementById('node-' + id)
  //                   .addEventListener('mouseup', node_mouseup, false);
  //                 mult_arr.push(id);
  //               }
  //             },

  //             onDeselect: function (el) {
  //               node_remove_listener(el);
  //             },

  //             enabled: true,
  //           });

  //           let rb = function () {
  //             return document.getElementById('s-rectBox');
  //           };

  //           let cross = function (a, b) {
  //             let aTop = offset(a).top,
  //               aLeft = offset(a).left,
  //               bTop = offset(b).top,
  //               bLeft = offset(b).left;
  //             return !(
  //               aTop + a.offsetHeight < bTop ||
  //               aTop > bTop + b.offsetHeight * editorState.zoom ||
  //               aLeft + a.offsetWidth < bLeft ||
  //               aLeft > bLeft + b.offsetWidth * editorState.zoom
  //             );
  //           };

  //           let offset = function (el) {
  //             let r = el.getBoundingClientRect();
  //             return {
  //               top: r.top + document.body.scrollTop,
  //               left: r.left + document.body.scrollLeft,
  //             };
  //           };

  //           dr.select = function (e) {
  //             let a = rb();
  //             if (!a) {
  //               return;
  //             }
  //             delete dr.ipos;
  //             document.body.classList.remove('s-noselect');
  //             document.body.removeEventListener('mousemove', dr.rectDraw);
  //             window.removeEventListener('mouseup', dr.select);
  //             let s = dr.options.selectedClass;
  //             dr.foreach(dr.items, function (el) {
  //               if (cross(a, el) === true) {
  //                 if (el.classList.contains(s)) {
  //                   el.classList.remove(s);
  //                   dr.options.onDeselect && dr.options.onDeselect(el);
  //                 } else {
  //                   el.classList.add(s);
  //                   dr.options.onSelect && dr.options.onSelect(el);
  //                 }
  //               }
  //               setTimeout(function () {
  //                 el.removeEventListener('click', dr.suspend, true);
  //               }, 100);
  //             });
  //             a.parentNode.removeChild(a);
  //             dr.options.stop && dr.options.stop(e);
  //           };

  //           function node_remove_listener(el) {
  //             if (el.closest('.drawflow-node').id.includes('node-') === true) {
  //               let temp_arr = [];
  //               for (const value of mult_arr) {
  //                 let id = parseInt(el.closest('.drawflow-node').id.slice(5));
  //                 if (value === id) {
  //                   document
  //                     .getElementById('node-' + value)
  //                     .removeEventListener('mousedown', node_mousedown, false);
  //                   document
  //                     .getElementById('node-' + value)
  //                     .removeEventListener('mouseup', node_mouseup, false);
  //                   temp_arr.push(value);
  //                 }
  //               }
  //               for (const value of temp_arr) {
  //                 mult_arr = mult_arr.filter(function (ele) {
  //                   return ele !== value;
  //                 });
  //               }
  //             }
  //           }

  //           function node_mousedown(e) {
  //             if (e.type === 'mousedown') {
  //               drag_start = true;
  //               active_node_id = parseInt(e.currentTarget.id.slice(5));
  //               for (let i = 1; i <= editorState.nodeId; i++) {
  //                 if (
  //                   typeof editorState.drawflow.drawflow.Home.data[i] !==
  //                   'undefined'
  //                 ) {
  //                   let node = editorState.getNodeFromId(active_node_id);
  //                   multiselect_dict[i] = {
  //                     pos_x:
  //                       editorState.drawflow.drawflow.Home.data[i].pos_x -
  //                       node.pos_x,
  //                     pos_y:
  //                       editorState.drawflow.drawflow.Home.data[i].pos_y -
  //                       node.pos_y,
  //                   };
  //                 }
  //               }
  //             }
  //           }

  //           function node_mouseup(e) {
  //             if (e.type === 'mouseup') {
  //               drag_start = false;
  //               active_node_id = null;
  //               multiselect_dict = {};
  //             }
  //           }

  //           document
  //             .getElementById('drawflow')
  //             .addEventListener('dblclick', clear_selection, false);

  //           function clear_selection(e) {
  //             dr.foreach(dr.items, function (el) {
  //               el.classList.remove(dr.options.selectedClass);
  //               node_remove_listener(el);
  //             });
  //             mult_arr = [];
  //           }
  //         }
  //         clearInterval(timer);
  //       }
  //     }, 500);
  //   };

  //   const checkAndAddImageToDrawComponent = useCallback(() => {
  //     const timer = setInterval(() => {
  //       if (
  //         JSON.stringify(editorState.drawflow.drawflow.Home.data) !==
  //         JSON.stringify({})
  //       ) {
  //         const imageBtns = document.querySelectorAll('.drawflow-image-upload');
  //         if (imageBtns) {
  //           imageBtns.forEach((imageBtn) => {
  //             const id = imageBtn.closest('.drawflow-node').id.slice(5);
  //             const node = editorState.getNodeFromId(id);
  //             const nodeData = node.data;
  //             const box = imageBtn.parentNode;
  //             box.setAttribute(
  //               'style',
  //               `background-image: url('${nodeData['df-image']}');
  //                 background-repeat:no-repeat;
  //                 background-size:100% 100%;
  //                 `
  //             );
  //           });
  //         }
  //         clearInterval(timer);
  //       }
  //     }, 500);
  //   }, [editorState]);

  //   const runBtn = useCallback(() => {
  //     const imageBtns = document.querySelectorAll('.drawflow-image-upload');

  //     if (imageBtns) {
  //       imageBtns.forEach((imageBtn) => {
  //         imageBtn.onchange = async (e) => {
  //           const id = e.target.closest('.drawflow-node').id.slice(5);
  //           const type = e.target.name;
  //           const file = e.target.files[0];
  //           const formData = new FormData();
  //           formData.append('file', file);
  //           const response = API.Post('/images/', formData, token);

  //           toast.promise(response, {
  //             loading: 'Uploading Image. Please wait...',
  //             success: ({ data }) => {
  //               const node = editorState.getNodeFromId(id);
  //               const nodeData = node.data;
  //               nodeData[
  //                 type
  //               ] = `http://localhost:5000/crm/api/images/${data.file.filename}`;
  //               const box = e.target.parentNode;
  //               box.setAttribute(
  //                 'style',
  //                 `background-image: url('http://localhost:5000/crm/api/images/${data.file.filename}');
  //                 background-repeat:no-repeat;
  //                 background-size:100% 100%;
  //                 `
  //               );
  //               editorState.updateNodeDataFromId(id, nodeData);
  //               editorStateDebounceFn(editorState);
  //               return `Image Uploaded`;
  //             },
  //             error: (error) => {
  //               console.log(error);
  //               return 'Something went wrong with the upload. Please try again later';
  //             },
  //           });
  //         };
  //       });
  //     }
  //   }, [editorState, editorStateDebounceFn, token]);

  //   const imageUploadListener = useCallback(() => {
  //     const observer = new MutationObserver((mutations) => {
  //       const deleteBtn = document.querySelectorAll('.drawflow-image-upload');

  //       if (deleteBtn) {
  //         runBtn();
  //         observer.disconnect();
  //       }
  //     });

  //     observer.observe(document.body, {
  //       childList: true,
  //       subtree: true,
  //       attributes: true,
  //     });
  //   }, [runBtn]);

  //   const runRemoveOptionsBox = useCallback(() => {
  //     const optionsListBoxs = document.querySelectorAll('.options-list-box');

  //     if (optionsListBoxs) {
  //       optionsListBoxs.forEach((optionListBox) => {
  //         const id = optionListBox.closest('.drawflow-node').id;
  //         editorState.removeNodeId(id);
  //       });
  //     }
  //   }, [editorState]);

  //   const optionsBoxListener = useCallback(() => {
  //     const observer = new MutationObserver((mutations) => {
  //       const optionsBox = document.querySelectorAll('.options-list-box');

  //       if (optionsBox) {
  //         runRemoveOptionsBox();
  //         observer.disconnect();
  //       }
  //     });

  //     observer.observe(document.body, {
  //       childList: true,
  //       subtree: true,
  //       attributes: true,
  //     });
  //   }, [runRemoveOptionsBox]);

  //   var elements = document.getElementsByClassName('drag-item');
  //   for (var i = 0; i < elements.length; i++) {
  //     elements[i].addEventListener('touchend', drop, false);
  //     elements[i].addEventListener('touchmove', positionMobile, false);
  //     elements[i].addEventListener('touchstart', drag, false);
  //   }

  //   useEffect(() => {
  //     if (window.performance.navigation.type === 1) {
  //       optionsBoxListener();
  //     }
  //   }, [editorState.drawflow.drawflow.Home.data]);

  //   useEffect(() => {
  //     runSelectable();
  //     checkAndAddImageToDrawComponent();
  //   }, [editorState.drawflow.drawflow.Home.data]);

  //   useEffect(() => {
  //     editorImageUploadListenerDebounceFn();
  //   }, [editorState]);

  //   useEffect(() => {
  //     if (state.publish) {
  //       const handleSubmit = async (e) => {
  //         const body = {
  //           id: id,
  //           publish: true,
  //         };

  //         try {
  //           const response = await API.Patch(
  //             platform === 'fb_hacker_challenge'
  //               ? `/challenge/${id}`
  //               : '/publish',
  //             body,
  //             token
  //           );
  //           const data = response.data;
  //           if (data.success) {
  //             swal('Flow has been Published', { icon: 'success' }).then(() => {
  //               navigate(-1);
  //             });
  //           }
  //           if (data.result) {
  //             swal('Challenge has been Published', { icon: 'success' }).then(
  //               () => {
  //                 navigate(-1);
  //               }
  //             );
  //           }
  //         } catch (error) {
  //           if (error.response.status === 500)
  //             return toast.error({
  //               title: 'Something went wrong',
  //               icon: 'error',
  //             });
  //         }
  //       };
  //       handleSubmit();

  //       dispatch({
  //         type: DRAWFLOW_ACTIONS.PUBLISH_FLOW,
  //         field: 'publish',
  //         payload: false,
  //       });
  //     }
  //   }, [dispatch, addFlow, state.publish]);
  return (
    <Modal
      open={openDrawflowModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        <Stack sx={{ margin: 0, marginRight: '40px' }}>
          <div
            ref={editorRef}
            id="drawflow"
            onDrop={drop}
            onDragOver={allowDrop}
            style={{ marginLeft: '-20px' }}
          ></div>
          <div className="bar-zoom" style={{ marginRight: '30px' }}>
            <IconButton onClick={changeMode}>
              {lockFlow ? <LockOpenIcon /> : <LockIcon />}
            </IconButton>
            <IconButton
              onClick={() => {
                editorState.zoom_in();
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                editorState.zoom_reset();
              }}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                editorState.zoom_out();
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </div>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
              marginRight: '30px',
              position: 'absolute',
              float: 'right',
              height: '50px',
              zIndex: '5',
              top: '10px',
              right: '10px',
              padding: '2px',
              color: 'white',
            }}
          >
            <Box
              sx={{
                background: 'rgba(0,0,0,0.2)',
                padding: 1,
                borderRadius: '10px',
              }}
            >
              {/* <Typography>Saving...</Typography> */}
              <Typography>Saved</Typography>
            </Box>
            <Button variant="contained">Publish</Button>
            <IconButton color="error" onClick={handleClose} size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DrawflowModal;
