import { Stack, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const AddPostComponent = ({ handleClick, date }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        border: '1px dashed gray',
        borderRadius: '8px',
        padding: '3px',
        cursor: 'pointer',
        height: '60px',
        background: '#ececec',
        width: '100%',
      }}
      onClick={() => handleClick('addPost', date)}
    >
      <AddIcon />
      <Typography sx={{ fontWeight: '700', fontSize: '13px' }}>
        Add Posting Time
      </Typography>
    </Stack>
  );
};

export default AddPostComponent;
