import { createContext, useReducer } from 'react';
import {
  drawflowInitalState,
  drawflowReducer,
} from './reducers/drawflowReducer';

export const DrawflowContext = createContext();

export const DrawflowContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(drawflowReducer, drawflowInitalState);

  return (
    <DrawflowContext.Provider value={[state, dispatch]}>
      {children}
    </DrawflowContext.Provider>
  );
};
