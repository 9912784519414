import Cookies from 'js-cookie';

export const authInitialState = {
  user: JSON.parse(Cookies.get('user') ?? null) ?? null,
  token: Cookies.get('access_token') || null,
  isAuthenticated: null,
  loading: false,
  error: null,
};

export const AUTH_ACTIONS = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  AUTH_ERROR: 'AUTH_ERROR',
  USER_LOADED: 'USER_LOADED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT: 'LOGOUT',
  LOADING: 'LOADING',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case AUTH_ACTIONS.LOGOUT:
      return { ...state, user: null, token: null };
    case AUTH_ACTIONS.LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
