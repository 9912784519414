export const validateInput = (name, value, password = '') => {
  let hasError = false,
    error = '';

  switch (name) {
    case 'name':
      if (value.trim() === '') {
        hasError = true;
        error = 'Field cannot be empty';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'email':
      if (value.trim() === '') {
        hasError = true;
        error = 'Field is required';
      } else if (
        !/^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(value)
      ) {
        hasError = true;
        error = 'Invalid Email. Please enter a valid email';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'password':
      if (value.trim() === '') {
        hasError = true;
        error = 'Field is required';
      } else if (!/^[\w@-]{8,20}$/.test(value)) {
        hasError = true;
        error =
          'Password must be alphanumeric (@, _ and - are allowed) and must be 8-20 characters';
      } else {
        hasError = false;
        error = '';
      }
      break;
    case 'confirmPassword':
      if (value.trim() === '') {
        hasError = true;
        error = 'Field is required';
      } else if (value.trim() !== password) {
        hasError = true;
        error = 'Passwords Does not match';
      } else {
        hasError = false;
        error = '';
      }
      break;
    default:
      break;
  }

  return { hasError, error };
};
