import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Button,
  Stack,
  Tooltip,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, Outlet } from 'react-router-dom';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import { AUTH_ACTIONS } from '../context/reducers/authReducer';
import { menuItems } from '../constants/menu_items';
import { useContext } from 'react';
import { Mysocial360Logo } from '../utils/logos';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import Cookies from 'js-cookie';
import CheckIcon from '@mui/icons-material/Check';
import toast from 'react-hot-toast';

const drawerWidth = 215;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: ' #f6f7f9',
  color: '#6e6d6d',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  background: ' #f6f7f9',
  color: '#6e6d6d',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#f6f7f9',

  marginTop: '1px',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const LargeScreenDrawer = ({ open, handleDrawerClose, handleLogout }) => {
  const theme = useTheme();
  return (
    <>
      <Drawer variant="permanent" open={open} sx={{ display: { xs: 'none',sm:'block' } }}>
        <DrawerHeader>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            // flex={1}
            // ml={1}
          >
            <Avatar src={Mysocial360Logo} />
            <Typography
              variant="h6"
              sx={{ fontWeight: '800', fontSize: '1rem' }}
            >
              Mysocial360
            </Typography>
          </Stack>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider height={2} />
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={NavLink}
                to={menuItem.url}
                sx={{
                  '&.active': {
                    background: 'rgba(25, 118, 210, 0.08)',
                  },
                  '&.active:hover': {
                    background: 'rgba(25, 118, 210, 0.15)',
                  },
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <Tooltip title={menuItem.title} placement="right">
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={menuItem.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Divider height={2} />
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={handleLogout}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={'Logout'} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PowerSettingsNewIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Drawer>
    </>
  );
};

export default LargeScreenDrawer;
