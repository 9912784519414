import {
  Box,
  Button,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const NewFlowModal = ({
  open,
  isEdit,
  handleEditName,
  flowName,
  handleFlowName,
  handleStartFlow,
  handleClose,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: { xs: 300, sm: 400 } }}>
          <form onSubmit={isEdit ? handleEditName : handleStartFlow}>
            <Stack spacing={3}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {isEdit ? 'Edit' : 'Add'} Flow Name
              </Typography>
              <TextField
                fullWidth
                autoFocus
                label="Flow Name"
                value={flowName}
                onChange={handleFlowName}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button type="submit" variant="contained">
                  {isEdit ? 'Edit Name' : 'Start Flow'}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default NewFlowModal;
