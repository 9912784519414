import React from 'react';
import Lottie from 'react-lottie';
import ComingSoon from '../assets/lottie_files/coming-soon.json';
import { Stack } from '@mui/material';

const ComingSoonComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ComingSoon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Stack>
      <Stack justifyContent="center" alignItems="center">
        <Lottie options={defaultOptions} />
      </Stack>
    </Stack>
  );
};

export default ComingSoonComponent;
