import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

function AuthRoutes() {
  const { user } = useAuthContext();

  return !user ? <Outlet /> : <Navigate to="/" replace />;
}

export default AuthRoutes;
