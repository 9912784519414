import { FBHackerLogo, DayLogo } from '../utils/logos';

import { fbHackerChallengeData } from './drawflow_default_data';

export default function getChallengeFlow(challenge) {
  if (!challenge) return fbHackerChallengeData;

  const { start, days, name } = challenge;

  const flow = {
    drawflow: {
      Home: {
        data: {
          1: {
            id: 1,
            name: 'fbHackerChallenge',
            data: { start: start },
            class: 'fbHackerChallenge',
            html: `\n <div>\n 
                            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
                                <img src=${FBHackerLogo} width="40" height="40"/>
                                ${name}
                            </div>\n
                            <div class="box">\n
                                <p>Start Your Challenge Flow</p>\n 
                            </div>\n
                            </div>\n `,
            typenode: false,
            inputs: {},
            outputs: {
              output_1: { connections: [] },
            },
            pos_x: 268,
            pos_y: 282,
          },
        },
      },
    },
  };

  for (let day = 0; day < days; day++) {
    const id = day + 2;
    flow.drawflow.Home.data[1].outputs.output_1.connections.push({
      node: `${id}`,
      output: 'input_1',
    });

    const data = {
      id: id,
      name: 'challenge-day',
      data: { message: '', title: '' },
      class: 'challenge-day',
      html: `\n<div>\n 
                <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
                    <img src=${DayLogo} width="40" height="40"/>
                    Day ${day + 1}
                </div>\n
                <div class="box">\n 
                    Add Your Content For Day ${day + 1}
                    <p>Add start time</p>
                    <input type="time" df-title placeholder="Start time" />\n 
                    <p>Add Description</p>
                    <textarea df-message placeholder="Description..."></textarea>\n
                </div>\n
                </div>\n`,
      typenode: false,
      inputs: {
        input_1: { connections: [{ node: `1`, input: 'output_1' }] },
      },
      outputs: {
        output_1: {
          connections: [],
        },
      },
      pos_x: 600 + day * 300,
      pos_y: 190,
    };

    flow.drawflow.Home.data[day + 2] = data;
  }

  console.log(flow.drawflow.Home.data);
  return flow;
}
