export const postInitialState = {
  events: [
    // { id: 1, date: '10/22/2022', title: 'ten', startTime: '10:25' },
    // { id: 2, date: '10/22/2022', title: 'two', startTime: '10:24' },
    // { id: 4, date: '10/22/2022', title: 'three', startTime: '10:23' },
    // { id: 3, date: '10/17/2022', title: 'random', startTime: '10:23' },
  ],
  loading: false,
};

export const POST_ACTIONS = {
  ADD_POST: 'ADD_POST',
  ADD_POSTS: 'ADD_POSTS',
  DELETE_POST: 'DELETE_POST',
  EDIT_POST: 'EDIT_POST',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
};

export const postReducer = (state, action) => {
  switch (action.type) {
    case POST_ACTIONS.ADD_POST:
      return {
        ...state,
        [action.field]: [...state[action.field], action.payload],
      };
    case POST_ACTIONS.ADD_POSTS:
      return {
        ...state,
        [action.field]: action.payload,
      };
    case POST_ACTIONS.DELETE_POST:
      return {
        ...state,
        [action.field]: state.events.filter(
          (event) => event.id !== action.payload
        ),
      };
    case POST_ACTIONS.EDIT_POST:
      return { ...state, user: null, token: null };
    // case POST_ACTIONS.DELETE_POST:
    //   return { ...state, loading: action.payload };
    case POST_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
