import { Avatar, Box, keyframes, Stack, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const PostComponent = ({ event, handleClick, handleOpenDrawflowModal }) => {
  const pulse = keyframes`{
    to {
      box-shadow: 0 0 0 12px transparent, 0 0 0 24px rgba(0, 0, 0, 0);
    }
  }`;
  return (
    <Stack
      spacing={1}
      sx={{
        borderBottom: '1px solid #c9c9c9 !important',
        borderLeft: '1px solid #c9c9c9 !important',
        borderRight: '1px solid #c9c9c9 !important',
        background:
          'linear-gradient(to right, #fdc468, #ffa263, #ff806c, #f9607f, #df4996) top/100% 5px no-repeat, #fff',
        borderRadius: '8px',
        padding: '6px',
        cursor: 'pointer',
        minHeight: '50px',
        width: '100%',
        position: 'relative',
      }}
      id={event.id}
    >
      <Stack
        onClick={() => handleClick(event.id, event.date)}
        sx={{ height: '100%', width: '100%' }}
        spacing={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={0.5}
        >
          <Stack direction="row" spacing={0.5}>
            <Avatar sx={{ width: 20, height: 20 }} />
            <Avatar sx={{ width: 20, height: 20 }} />
            <Avatar sx={{ width: 20, height: 20 }} />
          </Stack>
          <Typography
            sx={{ fontWeight: '500', fontSize: '10px', color: 'gray' }}
          >
            {event.startTime}
          </Typography>
        </Stack>
        <Typography sx={{ fontWeight: '700', fontSize: '13px' }}>
          {event.title}
        </Typography>
        <Stack direction="row" justifyContent="end" spacing={0.5}>
          <Avatar sx={{ width: 20, height: 20 }} />
          <Avatar sx={{ width: 20, height: 20 }} />
          <Avatar sx={{ width: 20, height: 20 }} />
        </Stack>
        <Stack direction="row" spacing={0.5}>
          <Avatar sx={{ width: 20, height: 20 }} />
          <Avatar sx={{ width: 20, height: 20 }} />
          <Avatar sx={{ width: 20, height: 20 }} />
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          right: -4,
          width: '10px',
          height: '10px',
          borderRadius: '50px',
          MozTransform: 'translate3d(0, 0, 0)',
          MsTransform: '  translate3d(0, 0, 0)',
          WebkitTransform: 'translate3d(0, 0, 0)',
          transform: 'translate3d(0, 0, 0)',
          display: 'block',
          lineHeight: '50px',
          textAlign: 'center',
          color: '#fff',
          border: 'none',
          boxShadow: '0 0 0 0 #f0f0f0, 0 0 0 0 rgba(0, 0, 0, 0.7)',
          vAlign: 'middle',
          background: '#000',
          cursor: 'pointer',
          animation: `${pulse} 1.25s infinite cubic- bezier(0.66, 0.33, 0, 1)`,
        }}
        onClick={() => handleOpenDrawflowModal()}
      ></Box>
    </Stack>
  );
};

export default PostComponent;
