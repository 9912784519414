import {
  BosLogo,
  ChatBosLogo,
  CliktrackerLogo,
  CopypasteLogo,
  CRMLogo,
  CursorLogo,
  EmojiLogo,
  FBHackerLogo,
  FBScraperLogo,
  FoeOrBroLogo,
  InnerCircleLogo,
  Mysocial360Logo,
  SplashLogo,
  StorieReelsLogo,
  FacebookLogo,
  MessageLogo,
  DayLogo,
} from '../utils/logos';
import { v4 as uuid } from 'uuid';

export function getText() {
  const html = `
        <div>
          <div class="title-box" style="display:flex; align-items:center; font-weight:700;"><img src="images/logo.png" alt="" class="logo">   Send a Message</div>
          <div class="box">
            <p>Add Trigger</p>
            <input type="text" df-trigger/>
            <p>Add Message</p> 
            <textarea df-message></textarea>
          </div>
        </div>
        `;
  return {
    html: html,
    id: 'text',
    class: 'dragElement',
    data: { message: '' },
  };
}

export function getImage() {
  const id = uuid();
  const html = `
    <div>
      <div
        class="title-box"
        style="display:flex; align-items:center; font-weight:700;"
      >
        <img src="images/logo.png" alt="" class="logo" /> Send an Image
      </div>
      <div class="box">
        <p>Add Trigger</p>
        <input type="text" df-trigger />
        <p>Add Image</p>
        <label for="drawflow-image-upload${id}" class="image-upload">
          <span class="plus"></span>
          <input hidden type="file" name="df-image" id="drawflow-image-upload${id}" class="drawflow-image-upload"/>
        </label>
      </div>
    </div>
  `;

  return {
    html: html,
    id: 'image',
    class: 'dragElement',
    data: {},
  };
}

export function getFBPost() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FacebookLogo} width="30" height="30"/>&nbsp;
        FB Post
      </div>\n 
      <div class="box">
            <p>Add Post time</p>
            <input type="time" df-time /> 
            <p>Add Title</p> 
            <input type="text" placeholder="Title" df-title /> 
            <p>Add Image Url</p> 
            <input type="text" placeholder="Image Url" df-imgUrl /> 
            <p>Add Content</p> 
            <textarea placeholder="Content..." df-text></textarea>
          </div> 
    </div>
    `;
  return {
    html: html,
    id: 'fbpost',
    class: 'dragElement',
    data: { time: '', title: '', description: '', imgUrl: '' },
  };
}

export function getFBMessenger() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${MessageLogo} width="30" height="30"/>&nbsp;
        Send Message
      </div>\n 
      <div class="box">
            <p>Add Message</p> 
            <textarea df-message></textarea>
          </div> 
    </div>
    `;
  return {
    html: html,
    id: 'messenger',
    class: 'dragElement',
    data: { message: '' },
  };
}

export function getDelay(defaultTime = 3) {
  const html = `
    <div>
    <div class="title-box" style="display:flex; align-items:center; font-weight:700;"> Delay </div>
    <div class="box">
      <p>Add your delay time</p>
      <input type="number" df-time>
    </div>
  </div>
        `;
  return {
    html: html,
    id: 'delay',
    class: 'dragElement',
    data: { time: defaultTime },
  };
}

export function getEmail() {
  const html = `
        <div>
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;"> Email </div>
            <div class="box">
                <p>Send Email</p>
            </div>
         </div>
        `;

  return {
    html: html,
    id: 'email',
    class: 'dragElement',
    data: {},
  };
}

export function getNotification() {
  const html = `
        <div>
            <div class="title-box" style="display:flex; align-items:center; font-weight:700;">Send Notification</div>
            <div class="box">
                <p>Send Notification</p>
            </div>
         </div>
        `;

  return {
    html: html,
    id: 'notification',
    class: 'dragElement',
    data: {},
  };
}

export function getChatbos() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${ChatBosLogo} width="30" height="30"/>&nbsp;
        Chatbos
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'chatbos',
    class: 'dragElement',
    data: {},
  };
}

export function getSocial360CRM() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CRMLogo} width="30" height="30"/>&nbsp;
        CRM
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'crm',
    class: 'dragElement',
    data: {},
  };
}

export function getMysocial360() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${Mysocial360Logo} width="30" height="30"/>&nbsp;
        Mysocial360
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'mysocial360',
    class: 'dragElement',
    data: {},
  };
}

export function getFbscraper() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FBScraperLogo} width="30" height="30"/>&nbsp;
        FB Scraper
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'fbscraper',
    class: 'dragElement',
    data: {},
  };
}

export function getStorieReels() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${StorieReelsLogo} width="30" height="30"/>&nbsp;
        Storie Reels
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'storiereels',
    class: 'dragElement',
    data: {},
  };
}

export function get360Emojis() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${EmojiLogo} width="30" height="30"/>&nbsp;
        360 Emojis
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: '360emojis',
    class: 'dragElement',
    data: {},
  };
}

export function getCopyPaste360() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CopypasteLogo} width="30" height="30"/>&nbsp;
        Copy Paste 360
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'copypaste360',
    class: 'dragElement',
    data: {},
  };
}

export function getCliktracker() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${CliktrackerLogo} width="30" height="30"/>&nbsp;
        Cliktracker
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'cliktracker',
    class: 'dragElement',
    data: {},
  };
}

export function getFoeOrBro() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FoeOrBroLogo} width="30" height="30"/>&nbsp;
        Foe or Bro
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'foeorbro',
    class: 'dragElement',
    data: {},
  };
}

export function getInnerCircleDeets() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${InnerCircleLogo} width="30" height="30"/>&nbsp;
        Inner Circle Deets
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'innercircledeets',
    class: 'dragElement',
    data: {},
  };
}

export function getFBGroupHacker() {
  const html = `
    <div>  
      <div class="title-box" style="display:flex; align-items:center; font-weight:700;">
        <img src=${FBHackerLogo} width="30" height="30"/>&nbsp;
        FB Group Master
      </div>\n  
    </div>
    `;
  return {
    html: html,
    id: 'fbgrouphacker',
    class: 'dragElement',
    data: {},
  };
}
