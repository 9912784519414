import React, { useEffect, useContext, useState } from 'react';
import { DrawflowContext } from '../context/drawflowContext';
import { DRAWFLOW_ACTIONS } from '../context/reducers/drawflowReducer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SendIcon from '@mui/icons-material/Send';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { apps } from '../constants/apps_data';
import Lottie from 'react-lottie';
import SelectionAnimation from '../assets/lottie_files/selection-list-clients.json';

const LivechatPage = ({ setIsFlow }) => {
  const [state, dispatch] = useContext(DrawflowContext);
  const [messages, setMessages] = useState(false);
  const [messageList, setMessageList] = useState({
    title: 'All',
    icon: null,
  });
  useEffect(() => {
    dispatch({
      type: DRAWFLOW_ACTIONS.IS_FLOW,
      field: 'isFlow',
      payload: false,
    });
  }, [dispatch, state.isFlow]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SelectionAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handlePlatform = (title, icon) => {
    setMessageList({ title, icon: icon ? icon : '' });
  };

  const handleMessages = () => {
    setMessages((prev) => !prev);
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <Stack direction="row" sx={{ width: '100%', height: '90vh' }}>
        <Stack
          spacing={2}
          sx={{
            width: '20%',
            height: '100%',
            background: '#F7F8F9',
            padding: '15px',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: '1rem', fontWeight: '600', color: '#49494A' }}
          >
            Conversations
          </Typography>

          <Stack spacing={2}>
            <List>
              {[{ title: 'All', icon: <ClearAllIcon /> }].map((item) => (
                <ListItem sx={{ padding: '0' }}>
                  <ListItemButton onClick={() => handlePlatform(item.title)}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      sx={{ width: '100%' }}
                    >
                      {item.icon}
                      <ListItemText>{item.title}</ListItemText>

                      <Typography>1</Typography>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
          <Divider />

          <Accordion
            defaultExpanded
            square
            sx={{
              background: 'inherit',
              border: 'none!important',

              paddingBottom: '20px',
            }}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="h6"
                sx={{ fontSize: '1rem', fontWeight: '600', color: '#49494A' }}
              >
                Channels
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: '0', height: '56vh', overflowY: 'scroll' }}
            >
              <List sx={{ padding: '0' }}>
                {apps.map((app) => (
                  <ListItem key={app.id} sx={{ padding: '0' }}>
                    <ListItemButton
                      onClick={() => handlePlatform(app.title, app.logo)}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar
                          src={app.logo}
                          sx={{ width: '30px', height: '30px' }}
                        />
                        <ListItemText sx={{ fontSize: '12px' }}>
                          {app.title}
                        </ListItemText>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Stack
          sx={{
            width: '20%',
            height: '100%',
            background: '#FCFDFD',
            paddingTop: '10px',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              paddingLeft: '10px',
              paddingBottom: '5px',
              marginBottom: '0px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            {messageList.icon ? <Avatar src={messageList.icon} /> : null}
            <Typography>{messageList.title}</Typography>
          </Stack>
          <List
            sx={{ padding: '0', overflowY: 'scroll', marginBottom: '10px' }}
          >
            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20,
            ].map((item) => (
              <ListItem sx={{ padding: '0' }}>
                <ListItemButton onClick={handleMessages}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar src="https://cdn.pixabay.com/photo/2021/06/15/16/11/man-6339003__340.jpg" />
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: '500',
                          color: '#49494A',
                        }}
                      >
                        John Doe
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '.8rem',
                          fontWeight: '300',
                          color: '#49494A',
                        }}
                      >
                        John Doe
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        color: '#49494A',
                      }}
                    >
                      6d
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack
          sx={{
            width: '60%',
            height: '100%',
            background: '#fff',
            padding: '5px',
          }}
        >
          {!messages ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Lottie options={defaultOptions} height={400} width={400} />
              <Typography variant="h6">
                Select a Conversation to start messaging
              </Typography>
            </Box>
          ) : (
            <>
              <Stack sx={{ padding: '5px' }} spacing={1}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Avatar src="https://cdn.pixabay.com/photo/2021/06/15/16/11/man-6339003__340.jpg" />
                  <Typography>John Doe</Typography>
                </Stack>
                <Divider />
              </Stack>
              <Box sx={{ flexGrow: 1, overflowY: 'scroll' }}>
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
                ].map((item) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: item % 2 ? 'flex-end' : 'flex-start',
                      padding: '15px',
                    }}
                  >
                    {item % 2 ? (
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Box
                          sx={{
                            background: item % 2 ? '#f0f8ff' : '#f8f8f8',
                            maxWidth: '200px',
                            minWidth: '25px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            borderRadius: '100px',
                            height: 'auto',
                            flexWrap: 'wrap',
                            padding: '10px',
                          }}
                        >
                          <Typography>
                            {item} sdfafdf afdfsa adfsd adfsds afdsfads adfsafd
                          </Typography>
                        </Box>
                        <Avatar
                          src={
                            item % 2
                              ? 'https://cdn.pixabay.com/photo/2016/11/21/14/53/man-1845814__340.jpg'
                              : 'https://cdn.pixabay.com/photo/2021/06/15/16/11/man-6339003__340.jpg'
                          }
                        />
                      </Stack>
                    ) : (
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          src={
                            item % 2
                              ? 'https://cdn.pixabay.com/photo/2016/11/21/14/53/man-1845814__340.jpg'
                              : 'https://cdn.pixabay.com/photo/2021/06/15/16/11/man-6339003__340.jpg'
                          }
                        />
                        <Box
                          sx={{
                            background: item % 2 ? '#f0f8ff' : '#f8f8f8',
                            maxWidth: '200px',
                            minWidth: '25px',
                            justifyContent: 'center',
                            display: 'flex',
                            borderRadius: '100px',
                            height: 'auto',
                            flexWrap: 'wrap',
                            padding: '10px',
                          }}
                        >
                          <Typography>
                            {item} sdfafdf afdfsa adfsd adfsds afdsfads adfsafd
                          </Typography>
                        </Box>
                      </Stack>
                    )}
                  </Box>
                ))}
              </Box>
              <Stack
                direction="row"
                sx={{
                  width: '100%',
                  marginTop: '10px',
                  marginBottom: '15px',
                }}
                spacing={1}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    multiline
                    maxRows={2}
                    placeholder="Message"
                  />
                </Box>
                <Button variant="contained" endIcon={<SendIcon />}>
                  Send
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default LivechatPage;
