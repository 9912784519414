import HomeIcon from '@mui/icons-material/Home';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TodayIcon from '@mui/icons-material/Today';

export const menuItems = [
  { title: 'Home', icon: <HomeIcon />, url: '/' },
  { title: 'Contacts', icon: <PersonIcon />, url: '/contacts' },
  { title: 'Automations', icon: <FlashAutoIcon />, url: '/apps' },
  { title: 'Live Chats', icon: <ChatIcon />, url: '/livechats' },
  { title: 'Broadcast', icon: <PodcastsIcon />, url: '/broadcast' },
  { title: 'Settings', icon: <SettingsIcon />, url: '/settings' },
  { title: 'Challenges', icon: <EventNoteIcon />, url: '/challenges' },
  { title: 'Scheduler', icon: <TodayIcon />, url: '/scheduler' },
];
