import { createContext, useReducer } from 'react';
import { postInitialState, postReducer } from './reducers/postReducer';

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(postReducer, postInitialState);

  return (
    <PostContext.Provider
      value={{ postState: { ...state }, postDispatch: dispatch }}
    >
      {children}
    </PostContext.Provider>
  );
};
