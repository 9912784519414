import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { PostContext } from '../../../context/postContext';
import { POST_ACTIONS } from '../../../context/reducers/postReducer';
import toast from 'react-hot-toast';
import API from '../../../utils/api';
import { useAuthContext } from '../../../hooks/useAuthContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  //   p: 2,
  border: 'none',
  borderRadius: '10px',
};

const PostModal = ({
  open,
  handleCreatePost,
  handleDeletePost,
  handleEditPost,
  handleClose,
  id,
  postDate,
}) => {
  const { token } = useAuthContext();
  const [category, setCategory] = useState('random');
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const { postState, postDispatch } = useContext(PostContext);

  const handleSubmit = () => {
    if (
      category === undefined ||
      startDate === undefined ||
      startTime === undefined
    )
      return toast.error('fill in all fields');

    const body = {
      name: category,
      startTime,
      startDate: postDate,
    };

    handleCreatePost(body);

    setCategory('random');
    setStartDate();
    setStartTime();
    handleClose();
  };

  const initDelete = () => {
    setDeleteClicked(true);
  };

  const handleDelete = () => {
    handleDeletePost(id);
    setDeleteClicked(false);
    handleClose();
  };

  const handleEdit = () => {
    if (
      category === undefined ||
      startDate === undefined ||
      startTime === undefined
    )
      return toast.error('fill in all fields');

    const sp = startDate.split('-');

    const body = {
      name: category,
      startTime: startTime,
      startDate: `${parseInt(sp[1], 10)}/${parseInt(sp[2], 10)}/${sp[0]}`,
    };

    handleEditPost(id, body);

    setCategory('random');
    setStartDate();
    setStartTime();
    handleClose();
  };

  useEffect(() => {
    if (id) {
      const event = postState.events.find((event) => event.id === id);

      console.log(event);

      if (event) {
        const sp = postDate.split('/');

        setCategory(event.title);
        setStartTime(event.startTime);
        setStartDate(
          `${sp[2]}-${sp[0].length === 1 ? `0${sp[0]}` : sp[0]}-${
            sp[1].length === 1 ? `0${sp[1]}` : sp[1]
          }`
        );
        return;
      }
    } else {
      if (postDate) {
        const sp = postDate.split('/');
        setStartDate(
          `${sp[2]}-${sp[0].length === 1 ? `0${sp[0]}` : sp[0]}-${
            sp[1].length === 1 ? `0${sp[1]}` : sp[1]
          }`
        );
        const dt = new Date();
        setStartTime(`${dt.getHours()}:${dt.getMinutes()}`);
        return;
      }
    }
  }, [id, postDate, postState.events]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: { xs: 300, sm: 800 } }}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6">
              {id && id !== ''
                ? `Edit your Posting time`
                : `Define your posting time`}
            </Typography>
          </Box>
          <Divider />
          <Stack mt={1} mb={2} p={2} spacing={2}>
            <Typography>
              Please choose the social profile you want to share on
            </Typography>
            <Stack direction="row" spacing={3}>
              <Avatar
                src="https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                sx={{ width: 64, height: 64 }}
              />
              <Avatar
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                sx={{ width: 64, height: 64 }}
              />
              <Avatar
                src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                sx={{ width: 64, height: 64 }}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack mt={3} p={2} spacing={2}>
            <FormControl>
              <InputLabel id="select-label">Select Category </InputLabel>
              <Select
                labelId="select-label"
                id="select-label"
                label="Select Category"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value="random">Random</MenuItem>
                <MenuItem value="ten">Ten</MenuItem>
                <MenuItem value="two">Two</MenuItem>
                <MenuItem value="three">Three</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <TextField
                type="date"
                value={startDate ?? ''}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="start-time"> </InputLabel>
              <TextField
                type="time"
                id="start-time"
                label="Select Start Time"
                value={startTime ?? ''}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Stack
            mt={5}
            p={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: '#e6e6e6',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            {id && id !== '' ? (
              !deleteClicked ? (
                <IconButton color="error" onClick={initDelete}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Typography
                  onClick={handleDelete}
                  color="error"
                  sx={{ cursor: 'pointer' }}
                >
                  Click again to confirm delete
                </Typography>
              )
            ) : (
              <Box></Box>
            )}
            <Stack direction="row" spacing={2}>
              <Button variant="text" color="inherit" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={id && id !== '' ? handleEdit : handleSubmit}
              >
                {id && id !== '' ? `Edit` : `Create`}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default PostModal;
