import { Box, Divider, Stack, Typography } from '@mui/material';
import { weekdays } from 'moment';
import React, { useEffect, useState } from 'react';
import AddPostComponent from '../AddPostComponent';
import DrawflowModal from '../DrawflowModal';
import PostComponent from '../PostComponent';
import PostModal from '../PostModal';
import './style.css';

const WeekDayComponent = ({
  weekDay,
  handleCreatePost,
  handleDeletePost,
  handleEditPost,
  headerColor,
  todayColor,
  fontColor,
}) => {
  const [open, setOpen] = useState(false);
  const [openDrawflowModal, setOpenDrawflowModal] = useState(false);
  const [postToEdit, setPostToEdit] = useState();
  const [postDate, setPostDate] = useState();

  const handleClick = (e, date) => {
    if (e === 'addPost') {
      setPostToEdit('');
      setPostDate(date);
    } else {
      setPostToEdit(e);
      setPostDate(date);
    }
    handleOpen();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDrawflowModal = () => setOpenDrawflowModal(true);
  const handleCloseDrawflowModal = () => setOpenDrawflowModal(false);

  return (
    <Stack
      alignItems="center"
      sx={{
        width: '100% ',
        padding: '8px',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', marginLeft: '-20px' }}
      >
        <Box
          className="weekly-view-header"
          sx={{
            background: !weekDay.isCurrentDay ? headerColor : todayColor,
            color: fontColor,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 6,
              padding: '10px',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '400',
              }}
            >
              {weekDay.day}
            </Typography>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 25,
              padding: '10px',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: '700',
                fontSize: '20px',
              }}
            >
              {weekDay.date}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack
        alignItems="center"
        spacing={2}
        sx={{
          width: '98%',
          marginTop: '7px',
          marginLeft: '-20px',
        }}
      >
        {weekDay.events
          .sort((a, b) => a.startTime.localeCompare(b.startTime))
          .map((event, index) => (
            <PostComponent
              key={index}
              event={event}
              handleClick={handleClick}
              date={weekDay.realDate}
              handleOpenDrawflowModal={handleOpenDrawflowModal}
            />
          ))}
        <AddPostComponent handleClick={handleClick} date={weekDay.realDate} />
        <PostModal
          open={open}
          handleClose={handleClose}
          id={postToEdit}
          postDate={postDate}
          handleCreatePost={handleCreatePost}
          handleDeletePost={handleDeletePost}
          handleEditPost={handleEditPost}
        />
        <DrawflowModal
          handleClose={handleCloseDrawflowModal}
          openDrawflowModal={openDrawflowModal}
        />
      </Stack>
    </Stack>
  );
};

export default WeekDayComponent;
