export const zoom_check = (zoom) => {
  switch (parseFloat(zoom).toFixed(1)) {
    case '1':
      return 0;
    case '1.1':
      return 1;
    case '1.2':
      return 2;
    case '1.3':
      return 3;
    case '1.4':
      return 4;
    case '1.5':
      return 5;
    case '1.6':
      return 6;
    case '0.1':
      return 9;
    case '0.2':
      return 8;
    case '0.3':
      return 7;
    case '0.4':
      return 6;
    case '0.5':
      return 5;
    case '0.6':
      return 4;
    case '0.7':
      return 3;
    case '0.8':
      return 2;
    case '0.9':
      return 1;
    default:
      return 0;
  }
};
