import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CRMLogo, Mysocial360Logo } from '../utils/logos';
import API from '../utils/api';
import { useAuthContext } from '../hooks/useAuthContext';
import { AUTH_ACTIONS } from '../context/reducers/authReducer';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import SocialMedia from '../assets/lottie_files/social-media-interaction.json';
import Lottie from 'react-lottie';

const Login = () => {
  const { loading, dispatch } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SocialMedia,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleGoogleAuth = () => {
    window.open(`${API.BASE_URL}/crm/api/auth/google`, '_self');
  };

  const handleFacebookAuth = () => {
    window.open(`${API.BASE_URL}/crm/api/auth/facebook`, '_self');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    setUserCredentials({
      ...userCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userCredentials.email === '' || userCredentials.password === '') {
      // setOpen(true);
      // setErrorMsg('Please fill in all fields');
      toast.error('Please fill in all fields');
      return;
    }

    const body = {
      email: userCredentials.email,
      password: userCredentials.password,
    };

    try {

      dispatch({ type: AUTH_ACTIONS.LOADING, payload: true });
      const res = await API.Post(`/auth/login`, body);

      const { access_token, user } = res.data;
      if (access_token) {
        Cookies.set('user', JSON.stringify(user));
        Cookies.set('access_token', access_token);
        dispatch({
          type: AUTH_ACTIONS.LOGIN_SUCCESS,
          payload: { token: access_token, user },
        });

        setTimeout(
          () => toast.success(`Welcome ${user?.name.split(' ')[0]}`),
          500
        );

        dispatch({ type: AUTH_ACTIONS.LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: AUTH_ACTIONS.LOADING, payload: false });
      if (error.response.status === 403) {
        toast.error(error.response.data.message);
      }
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      }
      if (error.response.status === 500) {
        toast.error('Server error');
      }
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          overflowY: 'scroll',
          background: '#e0dfdf',
        }}
      >
        <Stack
          justifyContent={{ xs: 'center' }}
          alignItems="center"
          sx={{
            background: '#fff',
            height: 'auto',
            width: { xs: '450px', sm: '740px' },
            borderRadius: '20px',
            marginTop: { xs: '300px', md: open ? '90px' : '0' },
            marginBottom: { xs: '60px', md: open ? '30px' : '0' },
            marginLeft: { xs: '25px' },
            marginRight: { xs: '25px' },
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Box
              sx={{
                background: '#f1f1f1',
                width: { xs: '350px', sm: '350px' },
                borderTopRightRadius: { xs: '20px', sm: '0px' },
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: { sm: '20px' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: '600', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                Welcome
              </Typography>
              <Lottie options={defaultOptions} height={300} width={300} />

              <Typography
                variant="h5"
                sx={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                Mysocial360
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: '300px', sm: '390px' },
                display: 'flex',
                flexDirection: 'column',
                padding: '25px',
                alignItems: 'center',
              }}
            >
              <Avatar src={Mysocial360Logo} sx={{ width: 117, height: 117 }} />
              <Typography
                variant="h5"
                sx={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.87)' }}
              >
                Login
              </Typography>
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <Stack
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  pt={1}
                  pb={4}
                >
                  {open && (
                    <Collapse in={open}>
                      <Alert
                        severity="error"
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        Login error — {errorMsg}!
                      </Alert>
                    </Collapse>
                  )}
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={userCredentials.email}
                    onChange={handleChange}
                    size="medium"
                  />
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={userCredentials.password.value}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    fullWidth
                    color="info"
                  >
                    Login
                  </Button>
                </Stack>
              </form>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleAuth}
                >
                  Login with Google
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<FacebookIcon />}
                  onClick={handleFacebookAuth}
                >
                  Login with Facebook
                </Button>
              </Stack>

              <Stack
                direction="row"
                pt={3}
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Typography>Don't have an account ?</Typography>
                <Link to="/register" style={{ textDecoration: 'none' }}>
                  <Button>Register</Button>
                </Link>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default Login;
