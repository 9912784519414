import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

function PrivateRoutes() {
  const { user } = useAuthContext();

  return user ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateRoutes;
